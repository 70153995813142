// Configuration
@import "./../../../../node_modules/bootstrap/scss/functions";

// https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss
// $primary: #1C7CD5;

$font-size-base: 0.9rem;

$font-family-sans-serif: 'Open Sans', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$primary: #1C7CD5;
// $success: #5CB85C;
$min-contrast-ratio: 4;

@import "./../../../../node_modules/bootstrap/scss/variables";


$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "white":      $white
);

$font-weight-semibold: 600;

$utilities: (
    "font-weight": (
        property: font-weight,
        class: fw,
        values: (
            light: $font-weight-light,
            lighter: $font-weight-lighter,
            normal: $font-weight-normal,
            semibold: $font-weight-semibold,
            bold: $font-weight-bold,
            bolder: $font-weight-bolder
        )
    ),
);

$grid-gutter-width: 1rem;

@for $i from 1 through 6 {
  $zIndexBackdrop:  #{1000 + (5 * $i)};
  $zIndexContent:  #{1000 + (5 * $i) + 2};
  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}

@import "./../../../../node_modules/bootstrap/scss/mixins";
@import "./../../../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "./../../../../node_modules/bootstrap/scss/root";
@import "./../../../../node_modules/bootstrap/scss/reboot";
@import "./../../../../node_modules/bootstrap/scss/type";
@import "./../../../../node_modules/bootstrap/scss/images";
@import "./../../../../node_modules/bootstrap/scss/containers";
@import "./../../../../node_modules/bootstrap/scss/grid";
@import "./../../../../node_modules/bootstrap/scss/tables";
@import "./../../../../node_modules/bootstrap/scss/forms";
@import "./../../../../node_modules/bootstrap/scss/buttons";
@import "./../../../../node_modules/bootstrap/scss/transitions";
@import "./../../../../node_modules/bootstrap/scss/dropdown";
@import "./../../../../node_modules/bootstrap/scss/button-group";
@import "./../../../../node_modules/bootstrap/scss/nav";
@import "./../../../../node_modules/bootstrap/scss/navbar";
@import "./../../../../node_modules/bootstrap/scss/card";
// @import "./../../../../node_modules/bootstrap/scss/accordion";
// @import "./../../../../node_modules/bootstrap/scss/breadcrumb";
@import "./../../../../node_modules/bootstrap/scss/pagination";
// @import "./../../../../node_modules/bootstrap/scss/badge";
@import "./../../../../node_modules/bootstrap/scss/alert";
// @import "./../../../../node_modules/bootstrap/scss/progress";
@import "./../../../../node_modules/bootstrap/scss/list-group";
@import "./../../../../node_modules/bootstrap/scss/close";
@import "./../../../../node_modules/bootstrap/scss/toasts";
@import "./../../../../node_modules/bootstrap/scss/modal";
@import "./../../../../node_modules/bootstrap/scss/tooltip";
// @import "./../../../../node_modules/bootstrap/scss/popover";
// @import "./../../../../node_modules/bootstrap/scss/carousel";
@import "./../../../../node_modules/bootstrap/scss/spinners";
// @import "./../../../../node_modules/bootstrap/scss/offcanvas";
// @import "./../../../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "./../../../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "./../../../../node_modules/bootstrap/scss/utilities/api";

