$pcp-blue: #0088FF;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

:root{
    --pcp-blue: #{$pcp-blue}
}
