@mixin scrollbar() {
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(170, 170, 170, 0.5);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(170, 170, 170, 0.5);
      border-radius: 6px;
    }
  }
}
